@import '~styles/framework';

.root {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.loading,
.error,
.empty {
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1 1 auto;
  padding: spacing(3);
  display: flex;
  align-items: center;
  justify-content: center;
}

.top {
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: spacing(3, 3, 2, 3);
  border-bottom: 1px solid get-color(mu);
}

.channels {
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1 1 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: spacing(2);
}
