@import '~styles/framework';

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.inner {
  width: 100%;
  max-width: 370px;
  color: get-color(alpha, 38);
}

.icon {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon svg {
  display: block;
  width: 120px;
  height: 120px;
}

.title,
.message {
  @include get-font-style(cartago);
}
