@import '~styles/framework';

.root {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: transparent;
  }
}

.video {
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  user-select: none;
  pointer-events: none;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: ease(opacity, medium);

  .isPlaying & {
    opacity: 1;
  }
}
