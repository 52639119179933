@import '~styles/framework';
@import './styles/icons';
@import './styles/duotone';

// Sync with $all-font-styles
$icon-styles: (
  kyoto: 20px,
  ericeira: 18px,
  madrid: 24px,
  lissabon: 24px,
  nuuk: 24px,
  honolulu: 24px,
  colombo: 24px,
);

.root {
  font-family: map-get($font-families, font-awesome);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}

.isDuotone::before {
  position: absolute;
}

.isDuotone::after {
  opacity: 0.5;
}

@each $style, $size in $icon-styles {
  .font-#{$style} {
    @include get-font-style($style);
    width: $size;
    min-width: $size;
    height: $size;
    min-height: $size;
  }
}
