* {
  &,
  &::before,
  &::after {
    box-sizing: border-box;
  }
}

html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  line-height: 1.5;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
dl,
dd,
ol,
ul,
form,
fieldset,
legend,
table,
figure,
th,
td,
caption,
hr {
  margin: 0;
  padding: 0;
}

abbr[title],
dfn[title] {
  cursor: help;
}

img {
  max-width: 100%;
  height: auto;
}

a img {
  border: 0;
}

button {
  border: 0;
  padding: 0;
  outline: none;
}

label,
button {
  cursor: pointer;
}

::-ms-clear {
  display: none;
}
