@import '~styles/framework';

.root {
  background-color: get-color(light);
  border: 1px solid get-color(mu);
  display: inline-flex;
  border-radius: 12px;
  overflow: hidden;
  padding: spacing(1, 0, 0, 0);

  :global {
    @include react-dates();
  }
}

.fullWidth {
  display: flex;
  width: 100%;
  justify-content: center;
}
