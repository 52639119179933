@import '~styles/framework';

.root {
  background-color: get-color(error, 90);
  padding: spacing(2, 0);
}

.inner {
  display: flex;
  align-items: center;
}

.message {
  flex: 1;
}

.elementRight {
  margin-left: spacing(2);
}
