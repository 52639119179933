@import '~styles/framework';

.root {
  padding: spacing(1.5);
  border-radius: 8px;
  min-width: 160px;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: currentcolor;

  &:hover {
    color: get-color(dark);
  }
}

.infoColor {
  background-color: get-color(info, 90);
  color: get-color(primary);
}

.lambdaColor {
  background-color: get-color(lambda);
  color: get-color(delta);
}

.amountAndLink {
  display: flex;
  align-items: center;
}

.amount {
  @include get-font-style(bukarest);
}

.title {
  @include get-font-style(cartago);
  margin-bottom: spacing(1);
}

.icon {
  color: currentcolor;
}

.loading,
.error,
.amount,
.amountSkeleton {
  flex: 1;
  display: flex;
  padding-right: spacing(1);
}

.error {
  @include get-font-style(kapstaden);
  font-style: italic;
}
