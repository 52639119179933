@import '~styles/framework';

$border-width: 1px;
$sizes: (
  sm: (
    padding-top-bottom: spacing(1),
    padding-right-left: spacing(1.5),
    icon-container-size: spacing(5),
    font-style: palermo,
    label-font-style: denpasar,
  ),
  md: (
    padding-top-bottom: spacing(1.5),
    padding-right-left: spacing(2),
    icon-container-size: spacing(5),
    font-style: kramfors,
    label-font-style: denpasar,
  ),
  lg: (
    padding-top-bottom: spacing(2),
    padding-right-left: spacing(2),
    icon-container-size: spacing(5),
    font-style: kramfors,
    label-font-style: cartago,
  ),
);
$colors: (
  light: (
    default: (
      background-color: get-color(nu),
      border-color: get-color(nu),
      color: get-color(dark),
      icon-color: get-color(alpha, 38),
      placeholder: get-color(alpha, 38),
    ),
    disabled: (
      background-color: get-color(alpha, 96),
      border-color: get-color(alpha, 96),
      color: get-color(alpha, 74),
    ),
    hover: (
      border-color: get-color(primary),
    ),
    focus: (
      border-color: get-color(primary),
    ),
  ),
);

.root {
  position: relative;
  display: inline-flex;
  flex-direction: column;
}

.disabled,
.disabled *,
.readOnly,
.readOnly * {
  cursor: not-allowed;
}

.label {
  color: get-color(dark);
  margin-bottom: spacing(1);
}

.textareaHolder {
  position: relative;
}

.textarea {
  border-radius: 8px;
  appearance: none;
  outline: none;
  border: none;
  width: 100%;
  display: block;
  max-width: 100%;
  resize: none;
  transition: ease(box-shadow), ease(color);
}

.iconRight,
.iconLeft {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  user-select: none;
  pointer-events: none;
  white-space: nowrap;
  transition: ease(color);
}

.iconRight {
  right: 0;
}

.iconLeft {
  left: 0;
}

@each $name, $styles in $colors {
  $default: map-get($styles, default);
  $disabled: map-get($styles, disabled);
  $hover: map-get($styles, hover);
  $focus: map-get($styles, focus);

  .#{$name}Color {
    .textarea {
      background-color: map-get($default, background-color);
      box-shadow: inset 0 0 0 $border-width map-get($default, border-color);
      color: map-get($default, color);

      @include placeholder() {
        color: map-get($default, placeholder);
      }
    }

    .iconRight,
    .iconLeft {
      color: map-get($default, icon-color);
    }

    &.disabled,
    &.readOnly {
      .textarea {
        background-color: map-get($disabled, background-color);
        box-shadow: inset 0 0 0 $border-width map-get($disabled, border-color);
        color: map-get($disabled, color);
      }
    }

    &:not(.disabled):not(.readOnly):not(.success):not(.error) {
      .textarea:hover {
        box-shadow: inset 0 0 0 $border-width map-get($hover, border-color);
      }

      .textarea:focus {
        box-shadow: inset 0 0 0 $border-width map-get($focus, border-color);
      }
    }

    &.success .textarea {
      box-shadow: inset 0 0 0 $border-width get-color(success);
    }

    &.error .textarea {
      box-shadow: inset 0 0 0 $border-width get-color(error);
    }
  }
}

@each $name, $styles in $sizes {
  $font-styles: map-get($all-font-styles, map-get($styles, font-style));

  .#{$name}Size {
    .textarea {
      @include get-font-style(map-get($styles, font-style));
      padding: map-get($styles, padding-top-bottom)
        map-get($styles, padding-right-left);
    }

    .label {
      @include get-font-style(map-get($styles, label-font-style));
    }

    &.rounded .textarea {
      border-radius: calc(
        (
            #{map-get($font-styles, line-height)} +
              (#{map-get($styles, padding-top-bottom)} * 2)
          ) /
          2
      );
    }

    &.hasIconRight .textarea {
      padding-right: #{map-get($styles, icon-container-size)};
    }

    &.hasIconLeft .textarea {
      padding-left: #{map-get($styles, icon-container-size)};
    }

    .iconRight,
    .iconLeft {
      width: map-get($styles, icon-container-size);

      .icon {
        height: calc(
          #{map-get($font-styles, line-height)} +
            (#{map-get($styles, padding-top-bottom)} * 2)
        );
        line-height: calc(
          #{map-get($font-styles, line-height)} +
            (#{map-get($styles, padding-top-bottom)} * 2)
        );
      }
    }
  }
}

.iconButton {
  display: block;
  pointer-events: auto;
  background-color: transparent;
}

.fullWidth {
  width: 100%;
  display: block;
}

.informationText {
  margin-top: spacing(1);
}
