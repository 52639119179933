@mixin react-dates() {
  :local(.dayContent) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 58px;
    height: 58px;
    border-radius: 8px;
    text-align: center;
    margin: 0 auto;
    transition: ease(color), ease(background-color), ease(box-shadow);
  }

  .CalendarMonth_caption {
    text-transform: capitalize;
    color: get-color(dark);
    margin-bottom: spacing(4);
    @include get-font-style(bogota);
    line-height: map-get(map-get($all-font-styles, bogota), font-size) + 2px;
    padding-right: spacing(3);
    padding-left: spacing(3);

    strong {
      font-weight: inherit;
    }
  }

  .DayPicker_weekHeader {
    top: spacing(9);
  }

  .DayPicker_weekHeader_li {
    text-transform: uppercase;
    color: get-color(dark);
    @include get-font-style(bogota);

    small {
      font-size: 100%;
    }
  }

  .CalendarDay {
    border-color: transparent;
    border-radius: 8px;
    @include get-font-style(denpasar);
    border: none !important;

    &,
    &:hover {
      background-color: transparent;
      color: currentcolor;
    }

    :local(.dayContent) {
      color: get-color(alpha, 18);
    }

    &:hover :local(.dayContent) {
      background-color: get-color(nu);
    }
  }

  .CalendarDay__blocked_out_of_range {
    &,
    &:hover,
    &:active {
      cursor: not-allowed;
      background-color: transparent;
      color: currentcolor;
    }

    :local(.dayContent),
    &:hover :local(.dayContent),
    &:active :local(.dayContent) {
      background-color: transparent;
      color: get-color(mu);
    }
  }

  .CalendarDay__selected,
  .CalendarDay__highlighted_calendar {
    &,
    &:hover {
      background-color: transparent;
      color: currentcolor;
    }

    :local(.dayContent),
    &:hover :local(.dayContent) {
      background-color: transparent;
      color: currentcolor;
      box-shadow: inset 0 0 0 2px get-color(primary);
    }
  }

  .CalendarDay__selected_span {
    &,
    &:hover {
      background-color: transparent;
      color: currentcolor;
    }

    :local(.dayContent),
    &:hover :local(.dayContent) {
      background-color: get-color(info, 90);
      color: get-color(dark);
    }
  }

  .CalendarDay__hovered_span {
    &,
    &:hover {
      background-color: transparent;
      color: currentcolor;
    }

    :local(.dayContent),
    &:hover :local(.dayContent) {
      background-color: get-color(info, 90);
      color: get-color(dark);
    }
  }

  .DayPickerNavigation :local(.navNext),
  .DayPickerNavigation :local(.navPrev) {
    position: absolute;
    top: 16px;
  }

  .DayPickerNavigation :local(.navNext) {
    right: 22px;
  }

  .DayPickerNavigation :local(.navPrev) {
    left: 22px;
  }
}
