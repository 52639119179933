$font-folder: '~assets/fonts/font-awesome';

@font-face {
  font-family: 'Font Awesome';
  font-style: normal;
  font-weight: 100;
  font-display: block;
  src:
    url('#{$font-folder}/fa-thin-100.woff2') format('woff2'),
    url('#{$font-folder}/fa-thin-100.ttf') format('truetype');
}

@font-face {
  font-family: 'Font Awesome';
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src:
    url('#{$font-folder}/fa-light-300.woff2') format('woff2'),
    url('#{$font-folder}/fa-light-300.ttf') format('truetype');
}

@font-face {
  font-family: 'Font Awesome';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src:
    url('#{$font-folder}/fa-regular-400.woff2') format('woff2'),
    url('#{$font-folder}/fa-regular-400.ttf') format('truetype');
}

@font-face {
  font-family: 'Font Awesome';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src:
    url('#{$font-folder}/fa-brands-400.woff2') format('woff2'),
    url('#{$font-folder}/fa-brands-400.ttf') format('truetype');
}

@font-face {
  font-family: 'Font Awesome';
  font-style: normal;
  font-weight: 800; // default 900, but we'll set 800 so it doesn't interfere with solid
  font-display: block;
  src:
    url('#{$font-folder}/fa-duotone-900.woff2') format('woff2'),
    url('#{$font-folder}/fa-duotone-900.ttf') format('truetype');
}

@font-face {
  font-family: 'Font Awesome';
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src:
    url('#{$font-folder}/fa-solid-900.woff2') format('woff2'),
    url('#{$font-folder}/fa-solid-900.ttf') format('truetype');
}
