@import '~styles/framework';

$min-height: 32px;

.root {
  background-color: get-color(light);
  display: inline-flex;
  vertical-align: top;
  align-items: center;
  flex-direction: row;
  min-width: 0;
  max-width: 100%;
  border-radius: $min-height;
  min-height: $min-height;
  color: get-color(dark);
}

.disabled,
.disabled * {
  opacity: 0.4;
  cursor: not-allowed;
}

.fullWidth {
  display: flex;
  width: 100%;
}

.input {
  position: absolute;
  left: -9999px;
}

.number {
  flex: 1;
  text-align: center;
  @include truncate();
  @include get-font-style(cartago);
  min-width: 50px;
  user-select: none;
  padding: spacing(0, 2);
}

.stepDown,
.stepUp {
  color: get-color(dark);
  background-color: get-color(mu);
  width: $min-height;
  min-height: $min-height;
  border-radius: $min-height;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: ease(background-color);

  .root:not(.disabled) &:hover {
    background-color: get-color(mu, 80);
  }
}
