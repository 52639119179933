@import '~styles/framework';

.crumb {
  display: flex;
  flex-direction: row;
  align-items: center;
  @include get-font-style(palermo);
}

.link {
  text-decoration: none;
  color: get-color(dark);
}

.current {
  color: get-color(alpha, 46);
}

.icon {
  margin: spacing(0, 0.5);
}
