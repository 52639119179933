@import '~styles/framework';

$border-width: 1px;
$sizes: (
  xs: (
    padding-top-bottom: spacing(0.5),
    padding-right-left: spacing(1.5),
    icon-container-size: spacing(2),
    font-style: palermo,
    label-font-style: denpasar,
    text-sides-font-style: kapstaden,
  ),
  sm: (
    padding-top-bottom: spacing(1),
    padding-right-left: spacing(1.5),
    icon-container-size: spacing(2),
    font-style: palermo,
    label-font-style: denpasar,
    text-sides-font-style: oslo,
  ),
  md: (
    padding-top-bottom: spacing(1.5),
    padding-right-left: spacing(2),
    icon-container-size: spacing(2.5),
    font-style: kramfors,
    label-font-style: denpasar,
    text-sides-font-style: oslo,
  ),
  lg: (
    padding-top-bottom: spacing(2),
    padding-right-left: spacing(2),
    icon-container-size: spacing(3),
    font-style: kramfors,
    label-font-style: cartago,
    text-sides-font-style: oslo,
  ),
);
$colors: (
  light: (
    default: (
      background-color: get-color(nu),
      border-color: get-color(nu),
      color: get-color(dark),
      icon-color: get-color(alpha, 38),
      placeholder: get-color(alpha, 38),
    ),
    disabled: (
      background-color: get-color(alpha, 96),
      border-color: get-color(alpha, 96),
      color: get-color(alpha, 74),
    ),
    hover: (
      border-color: get-color(primary),
    ),
    focus: (
      border-color: get-color(primary),
    ),
  ),
);

.root {
  position: relative;
  display: inline-block;
}

.disabled,
.disabled *,
.readOnly,
.readOnly * {
  cursor: not-allowed;
}

.label {
  color: get-color(dark);
  margin-bottom: spacing(1);
}

.inputHolder {
  position: relative;
}

.input {
  border-radius: 8px;
  appearance: none;
  outline: none;
  border: none;
  width: 100%;
  display: block;
  transition: ease(box-shadow), ease(color);
}

.iconRight,
.iconLeft,
.textRight,
.textLeft {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  pointer-events: none;
  white-space: nowrap;
  transition: ease(color);
}

.iconRight,
.textRight {
  right: 0;
}

.iconLeft,
.textLeft {
  left: 0;
}

@each $name, $styles in $colors {
  $default: map-get($styles, default);
  $disabled: map-get($styles, disabled);
  $hover: map-get($styles, hover);
  $focus: map-get($styles, focus);

  .#{$name}Color {
    .input {
      background-color: map-get($default, background-color);
      box-shadow: inset 0 0 0 $border-width map-get($default, border-color);
      color: map-get($default, color);

      @include placeholder() {
        color: map-get($default, placeholder);
      }
    }

    .iconRight,
    .iconLeft,
    .textRight,
    .textLeft {
      color: map-get($default, icon-color);
    }

    &.disabled,
    &.readOnly {
      .input {
        background-color: map-get($disabled, background-color);
        box-shadow: inset 0 0 0 $border-width map-get($disabled, border-color);
        color: map-get($disabled, color);
      }

      .textRight,
      .textLeft {
        color: map-get($disabled, color);
      }
    }

    &:not(.disabled):not(.readOnly):not(.success):not(.error) {
      .input:hover {
        box-shadow: inset 0 0 0 $border-width map-get($hover, border-color);
      }

      .input:focus {
        box-shadow: inset 0 0 0 $border-width map-get($focus, border-color);
      }
    }

    &.success .input {
      box-shadow: inset 0 0 0 $border-width get-color(success);
    }

    &.error .input {
      box-shadow: inset 0 0 0 $border-width get-color(error);
    }
  }
}

@each $name, $styles in $sizes {
  $font-styles: map-get($all-font-styles, map-get($styles, font-style));

  .#{$name}Size {
    .input {
      @include get-font-style(map-get($styles, font-style));
      padding: map-get($styles, padding-top-bottom)
        map-get($styles, padding-right-left);
    }

    .label {
      @include get-font-style(map-get($styles, label-font-style));
    }

    &.rounded .input {
      border-radius: calc(
        #{map-get($font-styles, line-height)} +
          (#{map-get($styles, padding-top-bottom)} * 2)
      );
    }

    &.hasIconRight .input,
    &.hasTextRight .input {
      padding-right: calc(
        #{map-get($styles, icon-container-size)} +
          #{map-get($styles, padding-right-left)} +
          #{spacing(1)}
      );
    }

    &.hasIconLeft .input,
    &.hasTextLeft .input {
      padding-left: calc(
        #{map-get($styles, icon-container-size)} +
          #{map-get($styles, padding-right-left)} +
          #{spacing(1)}
      );
    }

    .iconRight,
    .iconLeft,
    .textRight,
    .textLeft {
      width: map-get($styles, icon-container-size);
    }

    .iconRight,
    .textRight {
      right: map-get($styles, padding-right-left);
    }

    .iconLeft,
    .textLeft {
      left: map-get($styles, padding-right-left);
    }

    .textRight,
    .textLeft {
      @include get-font-style(map-get($styles, text-sides-font-style));
    }
  }
}

.iconButton {
  display: block;
  pointer-events: auto;
  background-color: transparent;
}

.fullWidth {
  width: 100%;
  display: block;
}

.informationText {
  margin-top: spacing(1);
}
