@import '~styles/framework';

.trigger {
  background-color: transparent;
  display: inline-block;
}

.inner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.top {
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: spacing(3, 3, 0, 3);
}

.topInner {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.middle {
  flex: 1 1 auto;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding: spacing(3);
}

.bottom {
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: spacing(3);
  border-top: 1px solid get-color(mu);
}

.description {
  text-align: center;

  &:last-child {
    margin-bottom: 0;
  }
}

.sectionHeading {
  @include get-font-style(cartago);
}

span.workExperience {
  display: inline-flex;
  align-items: center;
}

.workExperienceIcon {
  display: flex;
  margin-right: spacing(1);
}

.collectionSection {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: get-color(nu);
  border-bottom: 1px solid get-color(mu);
  margin: spacing(0, -3);
  padding: spacing(2);
}

.information {
  @include get-font-style(palermo);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.informationIcon {
  margin-right: spacing(0.25);
}

.loading,
.error,
.empty {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: spacing(8, 3);
}

.avatarHolder {
  position: relative;
  margin-bottom: spacing(2);
}

.toggleLocationFavourite {
  position: absolute;
  right: -2px;
  bottom: -2px;
  border: 2px solid get-color(light);
}

.modalClose {
  top: spacing(3);
  right: spacing(3);
}
