@import '~styles/framework';

$font-style: kramfors;
$font-styles: map-get($all-font-styles, $font-style);

.root {
  display: inline-flex;
  vertical-align: top;
  user-select: none;
  flex-direction: row;
  align-items: center;
  @include get-font-style($font-style);
}

.disabled {
  color: get-color(alpha, 60);
  cursor: not-allowed;
}

.input {
  position: absolute;
  left: -9999px;
}

.radioHolder {
  height: map-get($font-styles, line-height);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  color: get-color(alpha, 38);

  .disabled & {
    color: get-color(alpha, 74);
  }
}

.checkCircle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: ease(opacity);
}

.labelHolder {
  display: flex;
  align-items: center;
  padding-left: spacing(0.75);
}

.label {
  color: get-color(dark);

  .disabled & {
    color: get-color(alpha, 74);
  }
}

.input:checked + .radioHolder {
  color: get-color(primary);

  .disabled & {
    color: get-color(alpha, 74);
  }

  .checkCircle {
    opacity: 1;
  }
}

:global(.is-tabbing) .input:focus + .radioHolder .circle {
  @include is-tabbing();
}
