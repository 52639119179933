@import '~styles/framework';

.root {
  display: flex;
  flex-direction: row;
}

.inner {
  position: relative;
  background-color: get-color(nu);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  .rounded & {
    border-radius: 50%;
  }

  .aspectRatio1\:1 & {
    @include aspect-ratio(1, 1);
  }

  .aspectRatio76\:25 & {
    @include aspect-ratio(76, 25);
  }

  .width96px & {
    width: 96px;
  }

  .width80px & {
    width: 80px;
  }

  .width64px & {
    width: 64px;
  }

  .width56px & {
    width: 56px;
  }

  .width100\% & {
    width: 100%;
  }
}

.file {
  .width100\% & {
    width: 100%;
  }
}

.menu {
  .rounded & {
    transform: translate(0, 0) !important;
  }
}

.noImage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  inset: 0;
}

.spinner {
  position: absolute;
  inset: 0;
}

.noImageIcon {
  margin-bottom: spacing(0.5);
}

.noImageText {
  @include get-font-style(kapstaden);
}
