@import '~styles/framework';
@import './styles/sizes';
@import './styles/colors';

$border-width: 1px;

.root {
  display: inline-block;
}

.root :global(.react-select__control) {
  border: none;
  border-radius: 8px;
  min-height: 0;
  outline: none;
  cursor: pointer;
}

:global(.is-tabbing) .root :global(.react-select__control--is-focused) {
  @include is-tabbing();
}

.root :global(.react-select__indicator) {
  cursor: pointer;
  transition: ease(color);
}

.root :global(.react-select__menu) {
  z-index: 9;
}

.root :global(.react-select__menu),
.root :global(.react-select__menu-list) {
  border-radius: 8px;
}

.root :global(.react-select__menu-notice--no-options) {
  background-color: transparent;
}

.root :global(.react-select__option) {
  cursor: pointer;
  width: 100%;
  display: flex;
  vertical-align: top;
  flex-direction: row;
  align-items: center;
  transition: ease(color), ease(background-color);
}

.label {
  margin-bottom: spacing(1);
}

.optionInput {
  position: absolute;
  left: -9999px;
}

.optionCheckSquare {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: ease(opacity);
}

.optionInput:checked + .optionCheckboxHolder .optionCheckSquare {
  opacity: 1;
}

.optionCheckboxHolder {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
}

.optionSquare,
.optionCheckSquare {
  transition: ease(color), ease(opacity);
}

.root :global(.react-select__multi-value) {
  border-radius: 5px;
}

.root :global(.react-select__multi-value__label) {
  border-radius: 5px;
  font-size: 100%;
}

.root :global(.react-select__multi-value__remove) {
  border-radius: 5px;
  cursor: pointer;
  transition: ease(color);
}

.multiLabelWithNumber {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 0;
}

.multiLabelWithNumberLabel {
  flex: 1;
  @include truncate();
}

.multiLabelWithNumberNumber {
  text-align: center;
  border-radius: 5px;
}

.loadingIndicator :global(.react-select__indicator) {
  display: none;
}

.disabled {
  cursor: not-allowed;
}

.fullWidth {
  width: 100%;
  display: block;
}

.informationText {
  margin-top: spacing(1);
}

@each $name, $styles in $colors {
  $default: map-get($styles, default);
  $disabled: map-get($styles, disabled);
  $hover: map-get($styles, hover);

  .#{$name}Color {
    :global(.react-select__control) {
      background-color: map-get($default, root-background-color);
      box-shadow: inset
        0
        0
        0
        $border-width
        map-get($default, root-border-color);
      color: map-get($default, root-color);
    }

    &:not(.disabled):not(.error):not(.success)
      :global(.react-select__control):hover,
    :global(.react-select__control--is-focused),
    :global(.react-select__control--menu-is-open) {
      box-shadow: inset 0 0 0 $border-width map-get($hover, root-border-color);
    }

    &.error :global(.react-select__control) {
      box-shadow: inset 0 0 0 $border-width get-color(error);
    }

    &.success :global(.react-select__control) {
      box-shadow: inset 0 0 0 $border-width get-color(success);
    }

    :global(.react-select__placeholder) {
      color: map-get($default, placeholder-color);
    }

    :global(.react-select__single-value),
    :global(.react-select__input-container) {
      color: map-get($default, root-color);
    }

    :global(.react-select__indicator-separator) {
      background-color: map-get($default, indicator-separator-background-color);
    }

    :global(.react-select__dropdown-indicator) {
      color: map-get($default, dropdown-indicator-background-color);

      &:hover {
        color: map-get($hover, dropdown-indicator-background-color);
      }
    }

    :global(.react-select__clear-indicator) {
      color: map-get($default, clear-indicator-color);

      &:hover {
        color: map-get($hover, clear-indicator-color);
      }
    }

    :global(.react-select__menu) {
      background-color: map-get($default, menu-background-color);
      box-shadow: map-get($default, menu-box-shadow);
    }

    :global(.react-select__menu-notice--no-options) {
      color: map-get($default, menu-notice-no-options);
    }

    :global(.react-select__option) {
      color: map-get($default, option-color);

      &,
      &:active {
        background-color: transparent;
      }
    }

    :global(.react-select__option) .optionSquare {
      color: map-get($default, option-checkbox-color);
    }

    .optionInput:checked + .optionCheckboxHolder .optionCheckSquare {
      color: map-get($default, option-checkbox-is-focused-color);
    }

    :global(.react-select__option--is-selected) {
      &,
      &:active {
        background-color: map-get(
          $default,
          option-is-selected-background-color
        );
        color: map-get($default, option-is-selected-color);
      }

      .optionSquare {
        color: map-get($hover, option-checkbox-color);
      }

      /* stylelint-disable-next-line selector-max-compound-selectors */
      .optionInput:checked + .optionCheckboxHolder .optionCheckSquare {
        color: map-get($hover, option-checkbox-is-selected-color);
      }
    }

    :global(.react-select__option--is-focused) {
      &,
      &:active {
        background-color: map-get($default, option-is-focused-background-color);
        color: map-get($default, option-is-focused-color);
      }

      .optionSquare {
        color: map-get($hover, option-checkbox-color);
      }

      /* stylelint-disable-next-line selector-max-compound-selectors */
      .optionInput:checked + .optionCheckboxHolder .optionCheckSquare {
        color: map-get($hover, option-checkbox-is-focused-color);
      }
    }

    :global(.react-select__multi-value) {
      background-color: map-get($default, multi-value-background-color);
    }

    :global(.react-select__multi-value__label) {
      color: map-get($default, multi-value-label-color);
    }

    :global(.react-select__multi-value__remove) {
      background-color: transparent;
      color: map-get($default, multi-value-remove-color);

      &:hover {
        background-color: transparent;
        color: map-get($hover, multi-value-remove-color);
      }
    }

    .multiLabelWithNumberNumber {
      background-color: map-get(
        $default,
        multi-label-with-number-number-background-color
      );
      color: map-get($default, multi-label-with-number-number-color);
    }

    &.disabled :global(.react-select__control) {
      background-color: map-get($disabled, root-background-color);
      box-shadow: inset
        0
        0
        0
        $border-width
        map-get($disabled, root-border-color);
      color: map-get($disabled, root-color);
    }

    &.disabled :global(.react-select__placeholder) {
      color: map-get($disabled, placeholder-color);
    }

    &.disabled :global(.react-select__single-value),
    &.disabled :global(.react-select__input-container) {
      color: map-get($disabled, root-color);
    }

    &.disabled :global(.react-select__indicator-separator) {
      background-color: map-get(
        $disabled,
        indicator-separator-background-color
      );
    }

    &.disabled :global(.react-select__dropdown-indicator) {
      color: map-get($disabled, dropdown-indicator-background-color);
    }

    &.disabled :global(.react-select__clear-indicator) {
      color: map-get($disabled, clear-indicator-color);
    }

    &.disabled :global(.react-select__multi-value) {
      background-color: map-get($disabled, multi-value-background-color);
    }

    &.disabled :global(.react-select__multi-value__label) {
      color: map-get($disabled, multi-value-label-color);
    }

    &.disabled :global(.react-select__multi-value__remove) {
      color: map-get($disabled, multi-value-remove-color);
    }

    &.disabled .multiLabelWithNumberNumber {
      background-color: map-get(
        $disabled,
        multi-label-with-number-number-background-color
      );
      color: map-get($disabled, multi-label-with-number-number-color);
    }
  }
}

@each $name, $styles in $sizes {
  $label: map-get($styles, label);
  $value-container: map-get($styles, value-container);
  $placeholder: map-get($styles, placeholder);
  $single-value: map-get($styles, single-value);
  $input-container: map-get($styles, input-container);
  $indicator-separator: map-get($styles, indicator-separator);
  $dropdown-indicator: map-get($styles, dropdown-indicator);
  $clear-indicator: map-get($styles, clear-indicator);
  $menu: map-get($styles, menu);
  $menu-list: map-get($styles, menu-list);
  $menu-notice-no-options: map-get($styles, menu-notice-no-options);
  $option: map-get($styles, option);
  $option-checkbox-holder: map-get($styles, option-checkbox-holder);
  $multi-value: map-get($styles, multi-value);
  $multi-value-label: map-get($styles, multi-value-label);
  $multi-value-remove: map-get($styles, multi-value-remove);
  $multi-label-with-number: map-get($styles, multi-label-with-number);
  $multi-label-with-number-label: map-get(
    $styles,
    multi-label-with-number-label
  );
  $multi-label-with-number-number: map-get(
    $styles,
    multi-label-with-number-number
  );
  $loading-indicator: map-get($styles, loading-indicator);

  .#{$name}Size {
    .label {
      @include get-font-style(map-get($label, font-style));
    }

    &.root :global(.react-select__value-container) {
      padding: map-get($value-container, padding);
      @include get-font-style(map-get($value-container, font-style));
    }

    &.isSearchable :global(.react-select__value-container) {
      padding: map-get($value-container, padding-is-searchable);
    }

    &.root :global(.react-select__placeholder) {
      margin: map-get($placeholder, margin);
    }

    &.root :global(.react-select__single-value) {
      margin: map-get($single-value, margin);
    }

    &.root :global(.react-select__input-container) {
      margin: map-get($input-container, margin);
      padding: map-get($input-container, padding);
    }

    &.root :global(.react-select__indicator-separator) {
      margin: map-get($indicator-separator, margin);
      width: map-get($indicator-separator, width);
    }

    &.root :global(.react-select__dropdown-indicator) {
      padding: map-get($dropdown-indicator, padding);
    }

    &.root :global(.react-select__clear-indicator) {
      padding: map-get($clear-indicator, padding);
    }

    &.root :global(.react-select__menu) {
      margin: map-get($menu, margin);
      @include get-font-style(map-get($menu, font-style));
    }

    &.root :global(.react-select__menu-list) {
      padding: map-get($menu-list, padding);
      max-height: map-get($menu-list, max-height);
    }

    &.root :global(.react-select__menu-notice--no-options) {
      padding: map-get($menu-notice-no-options, padding);
      @include get-font-style(map-get($menu-notice-no-options, font-style));
    }

    &.root :global(.react-select__option) {
      padding: map-get($option, padding);
    }

    .optionCheckboxHolder {
      margin-right: map-get($option-checkbox-holder, margin-right);
    }

    &.root :global(.react-select__multi-value) {
      margin: map-get($multi-value, margin);
      @include get-font-style(map-get($multi-value, font-style));
    }

    &.root :global(.react-select__multi-value__label) {
      padding: map-get($multi-value-label, padding);
    }

    &.root :global(.react-select__multi-value__remove) {
      padding: map-get($multi-value-remove, padding);
    }

    .multiLabelWithNumber {
      padding: map-get($multi-label-with-number, padding);
    }

    .multiLabelWithNumberLabel {
      padding-right: map-get($multi-label-with-number-label, padding-right);
    }

    .multiLabelWithNumberNumber {
      @include get-font-style(
        map-get($multi-label-with-number-number, font-style)
      );
      height: map-get($multi-label-with-number-number, height);
      min-width: map-get($multi-label-with-number-number, min-width);
    }

    .loadingIndicator {
      padding: map-get($loading-indicator, padding);
    }
  }
}
