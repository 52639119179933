@import '~styles/framework';

.root {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.top {
  display: flex;
  align-items: center;
  user-select: none;
}

.navigation {
  display: flex;
  flex: 1;
}

.button {
  margin: 0 2px;
  display: block;
  flex: 1;
  min-height: 100%;
  text-align: center;
  position: relative;
  padding: spacing(0.5, 2);
  border-radius: 0;
  background-color: get-color(nu);
  @include get-font-style(kapstaden);
  color: get-color(dark);
  transition: ease(opacity), ease(background-color), ease(color);

  &:not(:disabled):hover,
  &.active {
    background-color: get-color(primary);
    color: get-color(light);
  }

  &.previous {
    background-color: get-color(info, 90);
  }

  &:disabled {
    cursor: not-allowed;
  }

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;

  &:not(.contentStripPadding) {
    padding-top: spacing(5);
  }
}
