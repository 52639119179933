@import '~styles/framework';

$input-width-default: 72px;
$input-width-with-seconds: 97px;
$button-width: 24px;
$border-width: 1px;
$border-radius: 8px;

.root {
  position: relative;
  display: inline-block;
  padding-right: $button-width;
}

.disabled,
.disabled *,
.readOnly,
.readOnly * {
  cursor: not-allowed;
}

.input {
  border-radius: 0;
  border-top-left-radius: $border-radius;
  border-bottom-left-radius: $border-radius;
  appearance: none;
  outline: none;
  border: none;
  display: block;
  background-color: get-color(nu);
  box-shadow: inset 0 0 0 $border-width get-color(nu);
  color: get-color(dark);
  @include get-font-style(palermo);
  padding: spacing(0.75, 1);
  width: $input-width-default;
  transition: ease(box-shadow), ease(color);

  .showSeconds & {
    width: $input-width-with-seconds;
  }

  .disabled &,
  .readOnly & {
    box-shadow: inset 0 0 0 $border-width get-color(alpha, 96);
    color: get-color(alpha, 74);
    background-color: get-color(alpha, 96);
  }

  .root:not(.disabled):not(.readOnly) & {
    &:hover {
      box-shadow: inset 0 0 0 $border-width get-color(primary);
    }

    &:focus {
      box-shadow: inset 0 0 0 $border-width get-color(primary);
    }
  }
}

.buttonAdd,
.buttonSubtract {
  position: absolute;
  right: 0;
  background-color: get-color(mu);
  border: none;
  width: $button-width;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0;
  color: get-color(alpha, 18);
  transition: ease(background-color);

  .disabled &,
  .readOnly & {
    background-color: get-color(alpha, 96);
    color: get-color(alpha, 74);
  }

  .root:not(.disabled):not(.readOnly) &:hover {
    background-color: get-color(mu, 80);
  }
}

.buttonAdd {
  top: 0;
  border-top-right-radius: $border-radius;
}

.buttonSubtract {
  bottom: 0;
  border-bottom-right-radius: $border-radius;
}

.fullWidth {
  &,
  .input {
    width: 100%;
  }
}
