@import '~styles/framework';

$thumbnail-size-one-to-one: 48px;
$thumbnail-size-group: 32px;

.root {
  background-color: transparent;
  padding: spacing(2);
  text-align: left;
  width: 100%;
  display: flex;
  border-radius: 8px;
  transition: ease(background-color);

  &:hover,
  &.active {
    background-color: get-color(nu);
  }
}

.inner {
  display: flex;
  align-items: center;
  min-width: 0;
  width: 100%;
}

.thumbnailGroup {
  min-width: $thumbnail-size-one-to-one;
  max-width: $thumbnail-size-one-to-one;
  min-height: $thumbnail-size-one-to-one;
  max-height: $thumbnail-size-one-to-one;
  position: relative;
  margin-right: spacing(2);
}

.thumbnail {
  margin-right: spacing(2);
  min-width: $thumbnail-size-one-to-one;
  max-width: $thumbnail-size-one-to-one;
  background-color: get-color(alpha, 93);
  @include aspect-ratio(1, 1);
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  border: 1px solid get-color(light);

  .thumbnailGroup & {
    margin: 0;
    position: absolute;
    min-width: $thumbnail-size-group;
    max-width: $thumbnail-size-group;

    &:nth-child(1) {
      top: 0;
      left: 0;
      z-index: 1;
    }

    &:nth-child(2) {
      bottom: 0;
      right: 0;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  min-width: 0;
  width: 100%;
}

.name {
  $circle-size: 10px;

  @include truncate();
  @include get-font-style(cartago);
  color: get-color(dark);
  position: relative;

  &::after {
    content: '';
    width: $circle-size;
    height: $circle-size;
    background-color: get-color(primary);
    position: absolute;
    top: 50%;
    margin-top: -($circle-size * 0.5);
    right: 0;
    border-radius: 50%;
    display: none;
  }

  .unreadMessages & {
    @include get-font-style(bogota);
    padding-right: calc(spacing(3) + $circle-size);

    &::after {
      display: block;
    }
  }
}

.oneToOneName {
  @include truncate();
  @include get-font-style(cartago);
  color: get-color(dark);
  position: relative;
}

.lastMessageAndLastSentAt {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.lastMessage {
  flex: 1;
  margin-right: spacing(3);
  @include truncate();
  @include get-font-style(palermo);
  color: get-color(alpha, 38);
  transition: ease(color);

  .unreadMessages & {
    @include get-font-style(denpasar);
    color: get-color(dark);
  }
}

.lastSentAt {
  @include get-font-style(palermo);
  color: get-color(alpha, 62);
}
