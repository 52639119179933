@import '~styles/framework';

$types: (
  light: (
    background-color: get-color(light),
    color: get-color(dark),
  ),
  dark: (
    background-color: get-color(dark),
    color: get-color(light),
  ),
  error: (
    background-color: get-color(error, 57),
    color: get-color(light),
  ),
  success: (
    background-color: get-color(success, 39),
    color: get-color(light),
  ),
  info: (
    background-color: get-color(info),
    color: get-color(light),
  ),
  warning: (
    background-color: get-color(warning, 64),
    color: get-color(dark),
  ),
);

.root {
  border-radius: 12px;
  padding: spacing(2);
  box-shadow: 0 4px 20px get-color(dark, null, 0.1);
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}

@each $type, $colors in $types {
  .#{$type} {
    background-color: map-get($colors, background-color);
    color: map-get($colors, color);

    .buttonDefaultChildren {
      color: map-get($colors, color);
    }
  }
}

.iconHolder {
  margin-right: spacing(2);
  display: flex;
}

.titleAndMessage {
  flex: 1;
}

.title {
  @include get-font-style(bogota);
}

.message {
  @include get-font-style(kramfors);
}

.button {
  margin-left: spacing(2);
  display: flex;
}

.buttonDefaultChildren {
  transition: ease(opacity);

  .button:hover & {
    opacity: 0.75;
  }
}
