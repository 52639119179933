@import '~styles/framework';

.workExperience {
  align-items: center;
}

.workExperienceText {
  margin-left: spacing(1);
}

.selectAllButton {
  white-space: nowrap;
}

.selectAllCheckboxHolder {
  display: flex;
  justify-content: flex-end;
}
