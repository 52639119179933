@import '~styles/framework';

.buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.button {
  &:not(:first-child) {
    margin-left: spacing(1);
  }
}
