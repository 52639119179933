@import '~styles/framework';

.root {
  display: inline-block;
  transition: ease(opacity);
}

.fullWidth,
.label {
  width: 100%;
  display: block;
}

.inner {
  position: relative;
}

.root .menu {
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translate(50%, 50%);
  border-radius: 50%;
}

.root .menuTrigger {
  background-color: get-color(light);
  box-shadow: 0 1px 8px 2px get-color(dark, null, 0.14);
  width: 32px;
  height: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: ease(background-color);

  &:hover {
    background-color: get-color(alpha, 96);
  }
}

.disabled {
  opacity: 0.4;
  cursor: not-allowed;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: transparent;
  }
}

.input {
  position: absolute;
  left: -9999px;
}
