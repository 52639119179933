@import '~styles/framework';

.inner {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.avatar {
  margin-right: spacing(3);
}

.tag {
  margin-left: spacing(1.5);
}

.paragraph {
  @include get-font-style(cartago);
}
