@import '~styles/framework';

$sizes: (
  sm: (
    padding-top-bottom: spacing(0.5),
    padding-right-left: spacing(1.5),
    font-style: quito,
  ),
  md: (
    padding-top-bottom: spacing(0.5),
    padding-right-left: spacing(2),
    font-style: denpasar,
  ),
  lg: (
    padding-top-bottom: spacing(1),
    padding-right-left: spacing(2),
    font-style: denpasar,
  ),
);
$colors: (
  primary: (
    background-color: map-get($bookings-color-codes, started),
    color: get-color(light),
  ),
  success: (
    background-color: map-get($bookings-color-codes, accepted),
    color: get-color(dark),
  ),
  error: (
    background-color: map-get($bookings-color-codes, canceled),
    color: get-color(dark),
  ),
  info: (
    background-color: get-color(info, 90),
    color: get-color(primary),
  ),
  warning: (
    background-color: map-get($bookings-color-codes, requested),
    color: get-color(dark),
  ),
  mu: (
    background-color: get-color(mu),
    color: get-color(dark),
  ),
  nu: (
    background-color: get-color(nu),
    color: get-color(dark),
  ),
  dark: (
    background-color: map-get($bookings-color-codes, completed),
    color: get-color(light),
  ),
  light: (
    background-color: get-color(mu),
    color: get-color(dark, 52),
  ),
);

.root {
  border-radius: 8px;
  display: inline-block;
  user-select: none;
}

.nowrap {
  white-space: nowrap;
  display: inline-flex;
}

@each $name, $styles in $sizes {
  $font-styles: map-get($all-font-styles, map-get($styles, font-style));

  .#{$name}Size {
    padding: map-get($styles, padding-top-bottom)
      map-get($styles, padding-right-left);
    @include get-font-style(map-get($styles, font-style));

    &.rounded {
      border-radius: calc(
        #{map-get($font-styles, line-height)} +
          (#{map-get($styles, padding-top-bottom)} * 2)
      );
    }
  }
}

@each $name, $styles in $colors {
  .#{$name}Color {
    background-color: map-get($styles, background-color);
    color: map-get($styles, color);
  }
}
