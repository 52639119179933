@import '~styles/framework';

.root {
  display: flex;
  align-items: center;
  justify-content: center;

  &.topTextAlign {
    text-align: center;
    flex-direction: column-reverse;
  }

  &.rightTextAlign {
    text-align: left;
    flex-direction: row;
  }

  &.bottomTextAlign {
    text-align: center;
    flex-direction: column;
  }

  &.leftTextAlign {
    text-align: right;
    flex-direction: row-reverse;
  }
}

.text {
  flex: 1;

  .topTextAlign & {
    padding-bottom: spacing(1);
  }

  .rightTextAlign & {
    padding-left: spacing(1.5);
  }

  .bottomTextAlign & {
    padding-top: spacing(1);
  }

  .leftTextAlign & {
    padding-right: spacing(1.5);
  }

  .smSize & {
    @include get-font-style(skoghall);
  }

  .mdSize & {
    @include get-font-style(palermo);
  }

  .lgSize & {
    @include get-font-style(kramfors);
  }
}

.spinner {
  position: relative;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 50%;
  }
}

.smSize .spinner {
  width: 20px;
  height: 20px;

  &::before,
  &::after {
    border: 2px solid;
  }
}

.mdSize .spinner {
  width: 40px;
  height: 40px;

  &::before,
  &::after {
    border: 4px solid;
  }
}

.lgSize .spinner {
  width: 48px;
  height: 48px;

  &::before,
  &::after {
    border: 4px solid;
  }
}

.darkColor {
  .spinner {
    &::before {
      border-color: get-color(dark, null, 0.25);
    }

    &::after {
      border-color: get-color(dark) transparent transparent;
    }
  }

  .text {
    color: get-color(dark);
  }
}

.lightColor {
  .spinner {
    &::before {
      border-color: get-color(light, null, 0.25);
    }

    &::after {
      border-color: get-color(light) transparent transparent;
    }
  }

  .text {
    color: get-color(light);
  }
}

.primaryColor {
  .spinner {
    &::before {
      border-color: get-color(nu);
    }

    &::after {
      border-color: get-color(primary) transparent transparent;
    }
  }

  .text {
    color: get-color(dark);
  }
}

.spinner::after :global {
  animation: rotate ease(null, medium, linear) infinite forwards;
}
