$font-folder: '~assets/fonts/inter';

@font-face {
  font-family: Inter;
  font-weight: 100;
  font-style: normal;
  src: url('#{$font-folder}/inter-100.ttf');
  font-display: swap;
}

@font-face {
  font-family: Inter;
  font-weight: 200;
  font-style: normal;
  src: url('#{$font-folder}/inter-200.ttf');
  font-display: swap;
}

@font-face {
  font-family: Inter;
  font-weight: 300;
  font-style: normal;
  src: url('#{$font-folder}/inter-300.ttf');
  font-display: swap;
}

@font-face {
  font-family: Inter;
  font-weight: 400;
  font-style: normal;
  src: url('#{$font-folder}/inter-400.ttf');
  font-display: swap;
}

@font-face {
  font-family: Inter;
  font-weight: 500;
  font-style: normal;
  src: url('#{$font-folder}/inter-500.ttf');
  font-display: swap;
}

@font-face {
  font-family: Inter;
  font-weight: 600;
  font-style: normal;
  src: url('#{$font-folder}/inter-600.ttf');
  font-display: swap;
}

@font-face {
  font-family: Inter;
  font-weight: 700;
  font-style: normal;
  src: url('#{$font-folder}/inter-700.ttf');
  font-display: swap;
}

@font-face {
  font-family: Inter;
  font-weight: 800;
  font-style: normal;
  src: url('#{$font-folder}/inter-800.ttf');
  font-display: swap;
}

@font-face {
  font-family: Inter;
  font-weight: 900;
  font-style: normal;
  src: url('#{$font-folder}/inter-900.ttf');
  font-display: swap;
}
