@import '~styles/framework';

.root:not(.cover) {
  position: relative;
}

.placeholder-dark {
  background-color: get-color(dark);
}

.placeholder-mu {
  background-color: get-color(mu);
}

.cover {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.image {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: ease(opacity, medium);

  .loaded & {
    opacity: 1;
  }
}

.fit-fill .image {
  object-fit: fill;
}

.fit-contain .image {
  object-fit: contain;
}

.fit-cover .image {
  object-fit: cover;
}

.fit-none .image {
  object-fit: none;
}

.fit-scale-down .image {
  object-fit: scale-down;
}

.spinner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
