@import '~styles/framework';

.trigger {
  background-color: transparent;
  display: inline-block;
}

.error {
  .errorBackground & {
    background-color: get-color(light);
    padding: spacing(2, 4);
    border-radius: 12px;
  }
}
