@import '~styles/framework';

.root {
  position: fixed;
  right: spacing(1);
  bottom: spacing(1);
  left: spacing(1);
  z-index: 999;
  background-color: get-color(light);
  padding: spacing(2);
  border-radius: 5px;
  box-shadow: 0 2px 12px get-color(dark, null, 0.17);
  margin: 0 auto;
  max-width: 700px;
}
