@import '~styles/framework';

$font-style: kramfors;
$colors: (
  light: (
    background-color: get-color(light),
    color: get-color(dark),
    background-color-hover: get-color(nu),
    color-hover: get-color(dark),
  ),
);

.root {
  display: inline-block;
  position: relative;
  z-index: 9;
}

.trigger {
  display: block;
  background-color: transparent;
}

.menu {
  @include get-font-style($font-style);
  max-width: 320px;
  user-select: none;
  backface-visibility: hidden;
  z-index: 999;
}

.offsetHorizontal {
  padding: spacing(0, 1);
}

.offsetVertical {
  padding: spacing(1, 0);
}

.inner {
  border-radius: 8px;
  padding: spacing(0.5, 0);
  box-shadow: 0 4px 20px get-color(dark, null, 0.1);
  white-space: nowrap;
}

.item {
  min-width: 0;
  display: block;
  width: 100%;
  text-align: left;
  background-color: transparent;
  padding: spacing(1.5, 2.5);
  text-decoration: none;
  @include truncate();
}

.itemHover {
  transition: ease(background-color), ease(color);
}

@each $name, $styles in $colors {
  .#{$name}Color {
    .inner {
      background-color: map-get($styles, background-color);
    }

    .item {
      color: map-get($styles, color);

      &.itemHover:hover {
        background-color: map-get($styles, background-color-hover);
        color: map-get($styles, color-hover);
      }
    }
  }
}
