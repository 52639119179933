@import '~styles/framework';

.root {
  flex: 1 1 auto;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.loading,
.error,
.empty {
  padding: spacing(4);
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fetchMore {
  padding: spacing(5, 3, 2, 3);
  display: flex;
  justify-content: center;
  align-items: center;
}
