@import '~styles/framework';

.root {
  position: relative;
}

.textHolder {
  width: 100%;
  display: block;
  background-color: transparent;

  &,
  * {
    cursor: text;
  }
}

.textFieldset {
  border: none;
  padding: 0;
  pointer-events: none;
}

.datePicker {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  margin-top: spacing(1);
  opacity: 0;
  visibility: hidden;

  .datePickerVisible & {
    opacity: 1;
    visibility: visible;
  }
}
