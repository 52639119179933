@import '~styles/framework';

$max-width: 420px;
$background-me: get-color(primary);
$background-other: get-color(nu);
$color-me: get-color(light);
$color-other: get-color(dark);

.root {
  max-width: $max-width;
  display: flex;
  flex-direction: column;
  word-break: break-word;
  position: relative;

  &:not(:last-child) {
    margin-bottom: spacing(0.5);
  }

  &:not(.createdByMe) {
    align-self: flex-start;
  }

  &.createdByMe {
    align-self: flex-end;
  }
}

.text,
.fileInner {
  @include get-font-style(jokkmokk);
  border-radius: 12px;
  padding: spacing(1.5, 2);

  .root:not(.createdByMe) & {
    background-color: $background-other;
    color: $color-other;
  }

  .createdByMe & {
    background-color: $background-me;
    color: $color-me;
  }
}

.text a {
  color: currentcolor;

  &:hover {
    text-decoration: none;
    color: currentcolor;
  }
}

.textTitle {
  @include get-font-style(bogota);
  margin-bottom: spacing(1);
}

.imageHolder {
  display: flex;

  .createdByMe & {
    justify-content: flex-end;
  }
}

.imageInner {
  max-width: 100%;
}

.image {
  &,
  img {
    border-radius: 19px;
  }
}

.file {
  display: flex;

  .createdByMe & {
    justify-content: flex-end;
  }
}

.fileInner {
  background-color: transparent;
  color: currentcolor;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
  @include get-font-style(kramfors);
}

.iconFile {
  display: flex;
  margin-right: spacing(1);
}

.iconDownload {
  display: flex;

  .fileLoading & {
    opacity: 0;
  }
}

.fileSpinner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.iconAndSpinner {
  margin-left: spacing(3.5);
  width: 24px; // Need to do this because sizes are not in sync
  height: 24px; // Need to do this because sizes are not in sync
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.extra {
  margin-bottom: spacing(0.5);
  border-radius: 12px;
  background-color: $background-other;
  color: $color-other;
  display: flex;
  flex-direction: column;
  text-align: left;
  width: $max-width;

  .createdByMe & {
    background-color: $background-me;
    color: $color-me;
  }
}

.extraImage {
  width: 100%;
  height: 96px;
  position: relative;

  &,
  img {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
}

.extraContent {
  display: flex;
  align-items: center;
  padding: spacing(2);
  @include get-font-style(denpasar);
  width: 100%;
}

.extraContentLeft {
  flex: 1;
}

.extraContentRight {
  margin-left: spacing(2);
}

.extraName {
  @include get-font-style(cartago);
}

.readReceipt {
  position: absolute;
  bottom: 0;
  left: 100%;

  > i {
    color: get-color(primary);
  }

  .unreadReceiptIcon {
    min-width: 24px;
  }
}
