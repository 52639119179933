@import '~styles/framework';

.root {
  overflow: hidden;
}

.item {
  width: 100%;
}

.label {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  text-align: left;
  user-select: none;
  border-radius: 0;
  background-color: transparent;
  color: currentcolor;

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
}

.labelHolder {
  flex: 1;
}

.iconHolder {
  margin-left: spacing(2);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
}

.iconClosed,
.iconOpened {
  backface-visibility: hidden;
  transition: ease(opacity), ease(transform);
}

.iconClosed {
  .label.isActive & {
    opacity: 0;
    transform: rotate(-90deg);
  }
}

.iconOpened {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: rotate(90deg);

  .label.isActive & {
    opacity: 1;
    transform: rotate(0);
  }
}

.content {
  overflow: hidden;
}
