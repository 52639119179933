@import '~styles/framework';

$sizes: (
  sm: (
    padding-top-bottom: spacing(1),
    padding-right-left: spacing(1),
  ),
  md: (
    padding-top-bottom: spacing(2),
    padding-right-left: spacing(2),
  ),
  lg: (
    padding-top-bottom: spacing(3),
    padding-right-left: spacing(3),
  ),
);
$colors: (
  nu: (
    background-color: get-color(nu),
    border-color: get-color(nu),
    color: get-color(dark),
    box-shadow: null,
  ),
  kappa: (
    background-color: get-color(kappa),
    border-color: get-color(kappa),
    color: get-color(lambda),
    box-shadow: null,
  ),
  light: (
    background-color: get-color(light),
    border-color: get-color(mu),
    color: get-color(dark),
    box-shadow: 0 4px 20px rgba(get-color(dark), 0.04),
  ),
  warning: (
    background-color: get-color(warning, 90),
    border-color: get-color(warning, 90),
    color: get-color(dark),
  ),
);

.root {
  border-radius: 12px;
  min-height: 100%;
  border: 1px solid;
}

@each $name, $styles in $sizes {
  .#{$name}Size {
    padding: map-get($styles, padding-top-bottom)
      map-get($styles, padding-right-left);
  }
}

@each $name, $styles in $colors {
  .#{$name}Color {
    background-color: map-get($styles, background-color);
    border-color: map-get($styles, border-color);
    color: map-get($styles, color);
    box-shadow: map-get($styles, box-shadow);
  }
}

.label {
  @include get-font-style(bogota);
}
