@import '~styles/framework';

.root {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  outline: none;
}

.inner {
  position: fixed;
  top: 0;
  bottom: 0;
  max-width: 498px;
  background-color: get-color(light);
  width: calc(100% - #{spacing(map-get($container-paddings, horizontal))});
}

.content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
}

.rightAlign .inner {
  right: 0;
}

.leftAlign .inner {
  left: 0;
}

.close {
  position: absolute;
  top: spacing(2);
  right: spacing(2);
}
