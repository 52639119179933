@import '~styles/framework';

.root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: 1400px;
}

.shouldBeResponsive {
  min-width: 0;
}

.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
