@import '~styles/framework';

.root {
  color: get-color(dark);

  :global {
    .fc-daygrid-day {
      cursor: default;
      @include get-font-style(denpasar);

      &:not(.fc-day-other):not(.fc-day-disabled) {
        cursor: pointer;
      }

      &,
      &.fc-day-today {
        background-color: get-color(light);
      }
    }

    .fc-theme-standard td,
    .fc-theme-standard th {
      border-color: get-color(dark, 85);
    }

    .fc-daygrid-body {
      border-left: 1px solid get-color(dark, 85);
    }

    .fc-theme-standard th {
      border-right-color: transparent;
    }

    .fc-theme-standard .fc-scrollgrid {
      border: none;
    }

    .fc-day-other .fc-daygrid-day-top {
      opacity: 1;
    }

    .fc-header-toolbar.fc-toolbar {
      margin-bottom: spacing(4);
    }

    .fc-toolbar-title {
      text-transform: capitalize;
      @include get-font-style(rio);
    }

    .fc-theme-standard .fc-col-header-cell {
      text-align: right;
      border-right-color: transparent;
    }

    .fc-daygrid-day-number,
    .fc-col-header-cell-cushion {
      text-decoration: none;

      &,
      &:hover {
        color: get-color(dark);
      }
    }

    .fc-col-header-cell-cushion {
      padding: spacing(0, 1, 1, 0);
      @include get-font-style(denpasar);
    }

    .fc-daygrid-day-number {
      padding: spacing(1);
    }

    .fc-day-other .fc-daygrid-day-number {
      color: get-color(dark, 85);
    }

    .fc-highlight {
      background-color: get-color(dark, 95);
    }

    .fc-button {
      transition: ease(background-color), ease(color);

      &,
      &:not(:disabled):active,
      &:not(:disabled).fc-button-active {
        padding: 0;
        @include get-font-style(denpasar);
        padding: spacing(0.5, 1.5);
        border-radius: 5px;
        border: none;
        background-color: get-color(alpha, 26);
        color: get-color(light);
        border-color: get-color(alpha, 26);
      }

      &,
      &:not(:disabled):focus,
      &:not(:disabled):active:focus,
      &:not(:disabled).fc-button-active:focus {
        box-shadow: none;
      }

      &:not(:disabled):hover,
      &:not(:disabled):hover:active,
      &:not(:disabled):hover:focus {
        background-color: get-color(alpha, 46);
        color: get-color(light);
      }
    }
  }
}
