@import '~styles/framework';

.toasts {
  position: fixed;
  z-index: 999;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  max-width: 300px;
  width: 100%;
  margin: spacing(
    0,
    map-get($container-paddings, horizontal),
    map-get($container-paddings, horizontal),
    map-get($container-paddings, horizontal)
  );
}

.toast {
  width: 100%;
}

.container {
  padding-top: spacing(1);
}
