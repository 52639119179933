@import '~styles/framework';

.root {
  height: map-get($header-heights, root);
  background-color: get-color(light);
  color: get-color(dark);
  position: sticky;
  top: 0;
  z-index: 999;
  user-select: none;
  box-shadow: 0 2px 20px get-color(dark, null, 0.08);
}

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.logoHolder {
  height: map-get($header-heights, root);
  display: flex;
  align-items: center;
}

.logoHolder svg {
  width: 74px;
  height: 30px;
}

.navigation {
  flex: 1;
  line-height: map-get($header-heights, root);
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.navigationItem {
  text-decoration: none;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  @include get-font-style(denpasar);
  line-height: map-get($header-heights, root);
  height: map-get($header-heights, root);
  color: get-color(dark);
  padding: spacing(0, 2);
  position: relative;
  transition: ease(color);

  &::after {
    content: '';
    position: absolute;
    bottom: spacing(1.5);
    left: 50%;
    transform: translateX(-50%) scale(0);
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: get-color(primary);
    transition: ease(transform);
  }

  &:hover,
  &.navigationItemActive {
    color: get-color(primary);
  }

  &.navigationItemActive::after {
    transform: translateX(-50%) scale(1);
  }

  .menu & {
    padding-right: 0;
  }
}

.menuMenu {
  min-width: 252px;
}

.chat {
  display: flex;
  position: relative;
}

.chatHasUnreadMessages {
  position: absolute;
  top: -2px;
  right: -2px;
  width: 12px;
  height: 12px;
  background-color: get-color(error);
  border-radius: 50%;
}
