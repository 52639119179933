@import '~styles/framework';

$sizes: (
  sm: (
    track-width: 40px,
    track-height: 24px,
    knob-size: 20px,
    font-style: kapstaden,
  ),
  md: (
    track-width: 48px,
    track-height: 28px,
    knob-size: 24px,
    font-style: kapstaden,
  ),
  lg: (
    track-width: 52px,
    track-height: 32px,
    knob-size: 28px,
    font-style: kapstaden,
  ),
);

.root {
  display: inline-flex;
  vertical-align: top;
  user-select: none;
  flex-direction: row;
  align-items: center;
  color: get-color(dark);
  @include get-font-style(denpasar);
}

.disabled {
  cursor: not-allowed;
}

.input {
  position: absolute;
  left: -9999px;
}

.switchHolder {
  position: relative;
  background-color: get-color(alpha, 74);
  transition: ease(background-color);

  .disabled & {
    background-color: get-color(alpha, 96);
  }
}

.switchTextRight,
.switchTextLeft {
  position: absolute;
  top: 0;
  bottom: 0;
  @include truncate();
  text-align: center;
  transition: ease(opacity);
}

.switchTextRight {
  right: 0;
  color: get-color(dark);
  text-indent: -7px;
}

.switchTextLeft {
  left: 0;
  color: get-color(light);
  text-indent: 7px;
  opacity: 0;
}

.switch {
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  transition: ease(transform);

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: get-color(light);
    transition: ease(background-color);
  }

  .disabled &::after {
    background-color: get-color(alpha, 74);
  }
}

.labelRight {
  padding-left: spacing(1);
}

.labelLeft {
  padding-right: spacing(1);
}

.input:checked ~ .switchHolder {
  background-color: get-color(primary);

  .disabled & {
    background-color: get-color(alpha, 96);
  }

  .switchTextRight {
    opacity: 0;
  }

  .switchTextLeft {
    opacity: 1;
  }
}

@each $name, $styles in $sizes {
  .#{$name}Size {
    .switchHolder {
      min-width: map-get($styles, track-width);
      max-width: map-get($styles, track-width);
      min-height: map-get($styles, track-height);
      max-height: map-get($styles, track-height);
      border-radius: map-get($styles, track-height) * 0.5;
    }

    .input:checked ~ .switchHolder .switch {
      transform: translateX(
        map-get($styles, track-width) - map-get($styles, track-height)
      );
    }

    .switch {
      width: map-get($styles, track-height);
      height: map-get($styles, track-height);
      border-radius: map-get($styles, track-height) * 0.5;

      &::after {
        width: map-get($styles, knob-size);
        height: map-get($styles, knob-size);
        border-radius: map-get($styles, knob-size) * 0.5;
      }
    }

    .switchTextRight,
    .switchTextLeft {
      @include get-font-style(map-get($styles, font-style));
      line-height: map-get($styles, track-height);
      width: map-get($styles, track-width) - map-get($styles, track-height);
    }
  }
}

:global(.is-tabbing) .input:focus ~ .switchHolder {
  @include is-tabbing();
}
