@import '~styles/framework';

$font-style: denpasar;
$arrow-size: 4px;
$colors: (
  dark: (
    background-color: get-color(dark),
    color: get-color(light),
  ),
  light: (
    background-color: get-color(light),
    color: get-color(dark),
  ),
);

.trigger {
  display: inline-block;
  background-color: transparent;
}

.tooltip {
  @include get-font-style($font-style);
  z-index: 999;
  max-width: 350px;
  padding: spacing(1);
  pointer-events: none;
  user-select: none;
  backface-visibility: hidden;
}

.inner {
  border-radius: 5px;
  padding: spacing(1, 1.5);
  box-shadow: 0 2px 8px get-color(dark, null, 0.17);
}

.arrow {
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;

  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
  }
}

.tooltip[data-popper-placement*='top'] .arrow {
  bottom: spacing(1);

  &::after {
    top: 0;
    left: 0;
    margin-left: -$arrow-size;
    border-right: $arrow-size solid transparent;
    border-left: $arrow-size solid transparent;
  }
}

.tooltip[data-popper-placement*='right'] .arrow {
  left: spacing(1);

  &::after {
    top: 0;
    right: 0;
    margin-top: -$arrow-size;
    border-top: $arrow-size solid transparent;
    border-bottom: $arrow-size solid transparent;
  }
}

.tooltip[data-popper-placement*='bottom'] .arrow {
  top: spacing(1);

  &::after {
    bottom: 0;
    left: 0;
    margin-left: -$arrow-size;
    border-right: $arrow-size solid transparent;
    border-left: $arrow-size solid transparent;
  }
}

.tooltip[data-popper-placement*='left'] .arrow {
  right: spacing(1);

  &::after {
    top: 0;
    left: 0;
    margin-top: -$arrow-size;
    border-top: $arrow-size solid transparent;
    border-bottom: $arrow-size solid transparent;
  }
}

@each $name, $styles in $colors {
  .#{$name}Color {
    color: map-get($styles, color);

    .inner {
      background-color: map-get($styles, background-color);
    }

    &[data-popper-placement*='top'] .arrow {
      &::after {
        border-top: $arrow-size solid map-get($styles, background-color);
      }
    }

    &[data-popper-placement*='right'] .arrow {
      &::after {
        border-right: $arrow-size solid map-get($styles, background-color);
      }
    }

    &[data-popper-placement*='bottom'] .arrow {
      &::after {
        border-bottom: $arrow-size solid map-get($styles, background-color);
      }
    }

    &[data-popper-placement*='left'] .arrow {
      &::after {
        border-left: $arrow-size solid map-get($styles, background-color);
      }
    }
  }
}
