@import '~styles/framework';

$font-style: kramfors;
$font-styles: map-get($all-font-styles, $font-style);
$border-width: 1px;

.root {
  display: flex;
  width: 100%;
  user-select: none;
}

.disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.input {
  position: absolute;
  left: -9999px;
}

.content {
  background-color: get-color(light);
  box-shadow: inset 0 0 0 $border-width get-color(mu);
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  text-align: left;
  color: get-color(dark);
  border-radius: 8px;
  padding: spacing(1.5, 2);
  @include get-font-style($font-style);
  transition: ease(box-shadow);
}

.circleHolder {
  height: map-get($font-styles, line-height);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
}

.circle {
  color: get-color(alpha, 38);
}

.circleCheck {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  color: get-color(primary);
  transition: ease(opacity);
}

.label {
  flex: 1;
  margin-left: spacing(1);
}

.input:checked + .content {
  box-shadow: inset 0 0 0 $border-width get-color(primary);

  .circleCheck {
    opacity: 1;
  }
}

:global(.is-tabbing) .input:focus + .content {
  outline: 5px auto -webkit-focus-ring-color !important;
}
