@import '~styles/framework';

.root {
  display: table;
  width: 100%;
  table-layout: fixed;
  height: 100%;
  outline: none;
  padding: spacing(map-get($container-paddings, horizontal));
}

.body {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
}

.dialog {
  width: 100%;
  margin: 0 auto;
}

.smSize .dialog {
  max-width: 450px;
}

.mdSize .dialog {
  max-width: 608px;
}

.lgSize .dialog {
  max-width: 1240px;
}

.content {
  background-color: get-color(light);
  border-radius: 12px;
  position: relative;
  padding: spacing(4);

  .transparent & {
    background-color: transparent;
    padding: 0;
    border-radius: 0;
  }

  .stripPadding & {
    padding: 0;
    display: flex;
    flex-direction: column;
  }
}

.close {
  width: spacing(4);
  height: spacing(4);
  background-color: transparent;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  color: get-color(dark);
  transition: ease(color);

  .transparent & {
    position: fixed;
    color: get-color(light);
  }

  &:hover {
    color: get-color(primary);
  }
}
