@import '~styles/framework';

$max-width: 1240px;

.root {
  width: 100%;
  margin: 0 auto;
  max-width: $max-width;
}

.horizontal {
  padding-left: spacing(map-get($container-paddings, horizontal));
  padding-right: spacing(map-get($container-paddings, horizontal));
  max-width: calc(
    $max-width + spacing(map-get($container-paddings, horizontal) * 2)
  );
}

.vertical {
  padding-top: spacing(map-get($container-paddings, vertical));
  padding-bottom: spacing(map-get($container-paddings, vertical));
}

.fullWidth {
  max-width: none;
}
