@import '~styles/framework';

.trigger {
  background-color: transparent;
  display: inline-block;
}

.image {
  border-radius: 8px;
  overflow: hidden;
}
