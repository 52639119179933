@import '~styles/framework';

.selectAllButton {
  white-space: nowrap;
}

.selectAllCheckboxHolder {
  display: flex;
  justify-content: flex-end;
}
