@import '~styles/framework';

.top {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;

  .borderBottom &::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 2px;
    background-color: get-color(mu);
  }
}

.beside {
  padding-left: spacing(2);
}

.navigation {
  position: relative;
  min-width: 0;
  flex: 1;
}

.navigationInner {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: auto;
}

.button {
  display: block;
  text-align: center;
  user-select: none;
  padding: spacing(3);
  border-radius: 0;
  white-space: nowrap;
  background-color: transparent;
  @include get-font-style(cartago);
  color: get-color(alpha, 26);
  position: relative;
  transition: ease(opacity), ease(color);

  &::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 2px;
    z-index: 1;
    background-color: get-color(mu);
    transition: ease(height), ease(background-color);
  }

  &:not(:disabled):hover,
  &.active {
    &::after {
      height: 2px;
      background-color: get-color(primary);
    }
  }

  &.active {
    color: get-color(primary);
  }

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
}

.content {
  padding-top: spacing(3);
}
