@import '~styles/framework';

.root {
  padding: spacing(3);
  position: sticky;
  bottom: 0;
  background-color: get-color(light);
  border-top: 1px solid get-color(mu);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  &.isInModalMiddle {
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
  }
}

.navigation {
  display: flex;
  flex-direction: row;
  color: get-color(alpha, 26);
  @include get-font-style(denpasar);
}

.navigationText {
  background-color: transparent;
  padding: spacing(0, 2);
}

.navigationButton {
  text-decoration: none;
  border: none;
  background-color: transparent;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: get-color(alpha, 26);
  transition: ease(color), ease(opacity);

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &:not(:disabled):hover {
    color: get-color(alpha, 66);
  }

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
}

.paginationBeside {
  color: get-color(alpha, 26);
  @include get-font-style(denpasar);
}
