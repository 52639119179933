@import '~styles/framework';

.root {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.smSize {
  @include get-font-style(skoghall);
}

.mdSize {
  @include get-font-style(palermo);
}

.lgSize {
  @include get-font-style(kramfors);
}

.children {
  margin-top: 0;
}

.icon {
  .smSize &,
  .mdSize & {
    margin-right: spacing(1);
  }

  .lgSize & {
    margin-right: spacing(2);
  }
}

.infoStatus {
  color: get-color(info);
}

.successStatus {
  color: get-color(success);
}

.errorStatus {
  color: get-color(error, 57);
}

.warningStatus {
  color: get-color(warning);
}

.lightStatus {
  color: get-color(light);
}

.darkStatus {
  color: get-color(dark);
}
