@import '~styles/framework';

$max-width: 250px;

.root {
  width: 100%;
}

.outerBorder {
  border: 1px solid get-color(mu);
  border-radius: 5px;
}

.tableHolder {
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  @include get-font-style(denpasar);
}

.table {
  word-break: break-word;
  margin: 0;
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
}

.tr {
  thead & {
    text-align: left;
    border-bottom: 1px solid get-color(mu);

    .headBackground & {
      background-color: get-color(nu);
    }
  }

  tbody & {
    background-color: transparent;
    transition: ease(background-color);

    &:hover {
      background-color: transparent;
    }

    &:not(:last-child) {
      border-bottom: 1px solid get-color(mu);
    }
  }
}

.th {
  user-select: none;
  padding: spacing(2, 3);
  @include get-font-style(oslo);
  color: get-color(alpha, 18);

  .maxWidth & {
    max-width: $max-width;
  }

  .truncate & {
    @include truncate();
    max-width: $max-width;
  }
}

.thText {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.thIcon {
  color: get-color(alpha, 70);
  transition: ease(color);

  &.isSorted {
    color: get-color(alpha);
  }
}

.td {
  padding: spacing(1.5, 3);

  .maxWidth & {
    max-width: $max-width;
  }

  .truncate & {
    @include truncate();
    max-width: $max-width;
  }

  .lastTdRight &:last-child,
  &.tdWrap {
    width: 1%;
    white-space: nowrap;
  }
}

.spinnerHolder {
  padding: spacing(12, 3);
  display: flex;
  justify-content: center;
}

.emptyHolder {
  padding: spacing(12, 3);
  display: flex;
  justify-content: center;
  text-align: center;
}

.top {
  padding: spacing(0, 3, 3, 3);

  .topStripPaddingSides & {
    padding-right: 0;
    padding-left: 0;
  }
}

.topInner {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.topSearchAndFilter {
  flex: 1;
}

.topBeside {
  margin-left: spacing(3);
}
