@mixin optional-at-root($selector) {
  @at-root #{if(not &, $selector, selector-append(&, $selector))} {
    @content;
  }
}

@mixin placeholder() {
  @include optional-at-root('::-webkit-input-placeholder') {
    @content;
  }

  @include optional-at-root(':-moz-placeholder') {
    @content;
  }

  @include optional-at-root('::-moz-placeholder') {
    @content;
  }

  @include optional-at-root(':-ms-input-placeholder') {
    @content;
  }
}
